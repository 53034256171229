var exports = {};
exports = wiki;
wiki.displayName = "wiki";
wiki.aliases = [];

function wiki(Prism) {
  Prism.languages.wiki = Prism.languages.extend("markup", {
    "block-comment": {
      pattern: /(^|[^\\])\/\*[\s\S]*?\*\//,
      lookbehind: true,
      alias: "comment"
    },
    heading: {
      pattern: /^(=+)[^=\r\n].*?\1/m,
      inside: {
        punctuation: /^=+|=+$/,
        important: /.+/
      }
    },
    emphasis: {
      // TODO Multi-line
      pattern: /('{2,5}).+?\1/,
      inside: {
        "bold-italic": {
          pattern: /(''''').+?(?=\1)/,
          lookbehind: true,
          alias: ["bold", "italic"]
        },
        bold: {
          pattern: /(''')[^'](?:.*?[^'])?(?=\1)/,
          lookbehind: true
        },
        italic: {
          pattern: /('')[^'](?:.*?[^'])?(?=\1)/,
          lookbehind: true
        },
        punctuation: /^''+|''+$/
      }
    },
    hr: {
      pattern: /^-{4,}/m,
      alias: "punctuation"
    },
    url: [/ISBN +(?:97[89][ -]?)?(?:\d[ -]?){9}[\dx]\b|(?:RFC|PMID) +\d+/i, /\[\[.+?\]\]|\[.+?\]/],
    variable: [/__[A-Z]+__/, // FIXME Nested structures should be handled
    // {{formatnum:{{#expr:{{{3}}}}}}}
    /\{{3}.+?\}{3}/, /\{\{.+?\}\}/],
    symbol: [/^#redirect/im, /~{3,5}/],
    // Handle table attrs:
    // {|
    // ! style="text-align:left;"| Item
    // |}
    "table-tag": {
      pattern: /((?:^|[|!])[|!])[^|\r\n]+\|(?!\|)/m,
      lookbehind: true,
      inside: {
        "table-bar": {
          pattern: /\|$/,
          alias: "punctuation"
        },
        rest: Prism.languages.markup["tag"].inside
      }
    },
    punctuation: /^(?:\{\||\|\}|\|-|[*#:;!|])|\|\||!!/m
  });
  Prism.languages.insertBefore("wiki", "tag", {
    // Prevent highlighting inside <nowiki>, <source> and <pre> tags
    nowiki: {
      pattern: /<(nowiki|pre|source)\b[^>]*>[\s\S]*?<\/\1>/i,
      inside: {
        tag: {
          pattern: /<(?:nowiki|pre|source)\b[^>]*>|<\/(?:nowiki|pre|source)>/i,
          inside: Prism.languages.markup["tag"].inside
        }
      }
    }
  });
}

export default exports;